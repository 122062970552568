import { createTheme } from "@mui/material/styles"

const typography = {
    fontFamily: `'Poppins', sans-serif`
  }
const theme = createTheme({
    palette: {
        blue: {
          main: '#0047A9'
        }
    },
    typography,
})

export default theme
