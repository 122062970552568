/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from "moment-timezone";

import { Header, Footer } from "../index";

import { Divider, Paper, Typography } from '@mui/material';
import { getWagonClass, formatCurrency } from '../../common/Utils';
import { nanoid } from 'nanoid';
import uiConfig from "../../configs/uiConfig.json"

const SelectStationPriceCard = () => {
    const { stationpricecardPage } = uiConfig;
    const header = "Cari Jadwal Kereta";
    const navigate = useNavigate();
    const [trainScheduleData, setTrainScheduleData] = useState([]);

    const handleTiciketSelect = (i) => {
        let KAI = JSON.parse(localStorage.getItem("KAI"));

        KAI.ticketDetails = trainScheduleData[i];
        KAI.noka = trainScheduleData[i].noka
        localStorage.setItem("KAI", JSON.stringify(KAI));
        navigate("/inputdata");
    }

    useEffect(async () => {
        let KAI = await JSON.parse(localStorage.getItem("KAI"));
        if (!(KAI?.stationDetails && KAI?.trainScheduleResponse)) navigate("/");
        else {
            // remove any extra keys if available.
            KAI = {
                stationDetails: KAI.stationDetails,
                trainScheduleResponse: KAI.trainScheduleResponse
            }
            localStorage.setItem("KAI", JSON.stringify(KAI));
            setTrainScheduleData(KAI.trainScheduleResponse.data);
        }
    }, []);

    const getTripDuration = (departdatetime, arrivaldatetime) => {

        const start = moment(departdatetime, 'YYYY-MM-DD HH:mm');
        const end = moment(arrivaldatetime, 'YYYY-MM-DD HH:mm');
        const duration = moment.duration(end.diff(start));

        const hours = duration.hours();
        const minutes = duration.minutes();
        return `${hours}j ${minutes}m`;
    }

    const isTiciketAvailable = (departdatetime, availability) => {
        const KAI = JSON.parse(localStorage.getItem("KAI"));
        const adultPassengers = KAI.stationDetails.adultPassengers;
        const { isCardValidTimeInHours } = stationpricecardPage;

        if (availability == 0 || availability < adultPassengers) return false;
        return (moment(departdatetime, "YYYY-MM-DD HH:mm").diff(moment.tz("Asia/Jakarta").format("YYYY-MM-DD HH:mm"), "hours") >= isCardValidTimeInHours); // NOW SET TO 1 hour previously was 3 hours
    }

    const seatAvalibilityStatus = (availability) => {
        if (availability == 0) return <span style={{ fontSize: "12px" }} >Kursi habis</span>;
        if (availability > 50) return <span style={{ fontSize: "12px" }} >Tersedia</span>;
        else return <span style={{ fontSize: "12px" }} > {availability} Kursi Tersedia</span>;
    }

    return (
        <div style={{ height: trainScheduleData.length > 3 ? "" : "100vh" }}>
            <Header header={header} back={"/"} step={'2'} />
            <div style={{ padding: "16px", borderBottom: "1px solid #DDD", height: trainScheduleData.length > 3 ? "" : `calc(100vh - 151px)` }}> {/* 151px => height(Header) + height(Footer) + 16px(Top & Bottom Padding of this div) */}
                {
                    trainScheduleData.map(({ trainname, departuretime, arrivaltime, departdatetime, arrivaldatetime, stasiunorgcode, stasiundestcode, wagonclasscode, subclass, fares, departdate, arrivaldate, noka, availability }, i) => {
                        const tripDuration = getTripDuration(departdatetime, arrivaldatetime);
                        const wagonClass = getWagonClass(wagonclasscode, subclass);
                        const price = formatCurrency(fares[0].amount);
                        const isTicketValid = isTiciketAvailable(departdatetime, availability);
                        const ticketColor = isTicketValid ? "#777" : "#CCC";
                        return (
                            <Paper key={`train-ticket-container-${nanoid()}`}
                                id={'ticketInfo-'+i}
                                onClick={() => { if (isTicketValid) handleTiciketSelect(i) }}
                                style={{
                                    padding: "16px", borderRadius: "8px",
                                    background: isTicketValid ? "" : "#F2F2F2",
                                    boxShadow: isTicketValid ? "0px 2px 10px rgba(0, 0, 0, 0.2)" : "rgb(0 0 0 / 20%) 0px 0px 0px",
                                    marginBottom: "16px",
                                    cursor: isTicketValid ? "pointer" : "not-allowed"
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <Typography id="trainName" children={`${trainname} (${noka})`} style={{ color: isTicketValid ? "#1A1A1A" : "#CCC" }} className={"stationCardName"} />
                                        <Typography id="trainClass" children={wagonClass} style={{ color: ticketColor }} className={"stationCardClass"} />
                                    </div>
                                    <div>
                                        <Typography id="trainPriceCard" children={`${price}/orang`} style={{ color: isTicketValid ? "#0047A9" : "#CCC", whiteSpace: "pre" }} className={"stationCardPrice"} />
                                        {/* <Typography children={"Habis"} style={{display: isTicketValid ? "" : "none", fontWeight:"bold", fontSize:"10px", color:"#999", textAlign:"right"}} /> */}
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "16px" }}>
                                    <div style={{ display: "flex", marginTop: "16px" }}>
                                        <div>
                                            <Typography id="sourceStationCode" children={stasiunorgcode} style={{ color: ticketColor }} className={"stationTimeLabel"} />
                                            <Typography id="sourceStationTime" children={departuretime.substring(0, 2) + ":" + departuretime.substring(2, 4)} style={{ color: isTicketValid ? "#555" : "#CCC" }} className={"stationTime"} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Divider style={{ width: "5vw", margin: "0 10px" }} />
                                            <Typography id="journeyTime" children={tripDuration} style={{ fontSize: "12px", color: isTicketValid ? "#979797" : "#CCC" }} />
                                            <Divider style={{ width: "5vw", margin: "0 10px" }} />
                                        </div>
                                        <div>
                                            <Typography id="destinationStationCode" children={stasiundestcode} style={{ color: ticketColor }} className={"stationTimeLabel"} />
                                            <Typography id="destinationStationTime" children={arrivaltime.substring(0, 2) + ":" + arrivaltime.substring(2, 4)} style={{ color: isTicketValid ? "#555" : "#CCC" }} className={"stationTime"} />
                                            <Typography id="exceedsOneDay" children={"+1 hari"} style={{ color: isTicketValid ? "#999" : "#CCC", display: departdate != arrivaldate ? "" : "none" }} className={"stationTimeLabel"} />
                                        </div>
                                    </div>
                                    <Typography style={{ color: isTicketValid ? "#0047A9" : "#CCC", textAlign: "right" }} className={"stationCardPrice"} >
                                        {seatAvalibilityStatus(availability)}
                                    </Typography>
                                </div>
                            </Paper>
                        )
                    }
                    )
                }
            </div>
            <Footer />
        </div>
    )
};

export default SelectStationPriceCard;

