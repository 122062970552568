import React, { useEffect, useState } from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Indicators = ({selectedClass}) => {

    const [noOfPages, setNoOfPages] = useState([]);

    useEffect(() => {
        if(selectedClass) {
            const KAI = JSON.parse(localStorage.getItem('KAI'));
            const totalPages = KAI.seatList?.list?.classSeatData?.length
            const currentPageNo = parseInt(selectedClass.split(' ')[1].split('(')[1].split(')')[0]);
            
            let listOfIndicator = [];

            for (let index = 0; index < totalPages; index++) {
                listOfIndicator.push(
                    <FiberManualRecordIcon className={"indicator"} key={index} 
                        style={{"color": index+1 === currentPageNo ? "#0047A9" : "#AFAFAF"}} 
                    />
                );
            }
            setNoOfPages(listOfIndicator);
        }
    }, [selectedClass]);
    
    
    return (
        <div style={{width:"100%", textAlign:"center", paddingBottom: "16px"}}>
        { [...noOfPages] }            
        </div>
    )
}

export default Indicators;
